const menu = document.querySelector('.menu-hamburger');
const navLinks = document.querySelector('.nav-links');
const menuHamburger = document.querySelector('.menu-hamburger');
const nav = document.querySelector('nav');

menu.addEventListener('click', () => {
  navLinks.classList.toggle('mobile-menu');
  if (window.scrollY === 0 && navLinks.classList.contains('mobile-menu')) {
    menuHamburger.classList.remove('menu-hamburger-blue');
  }
  if (navLinks.classList.contains('mobile-menu')) {
    nav.classList.add('sticky');
  } else if (window.scrollY === 0) {
    nav.classList.remove('sticky');
    menuHamburger.classList.add('menu-hamburger-blue');
  }
});
window.addEventListener('scroll', computeNav);

function computeNav() {
  if (!navLinks.classList.contains('mobile-menu')) {
    menuHamburger.classList.toggle('menu-hamburger-blue', window.scrollY === 0);
    nav.classList.toggle('sticky', window.scrollY > 0);
  }
}

computeNav();
